<template>
    <el-card shadow="never">
        <template #header>
            <div class="clearfix" style="text-align:left">
                <span>套餐列表</span>
            </div>
        </template>
        <el-button type="primary" size="small" @click="SelectMerchant" style="margin-bottom:10px;"
            v-if="ismerchant != 1">选择商户</el-button>
        <el-tabs type="border-card" v-model="activeName" @tab-click="SwitchTab">
            <el-tab-pane v-for="channel in channels" :key="channel" :label="channel.label" :name="channel.name">
                <div class="content-header">
                    <el-row :gutter="5">
                        <el-col :span="24" style="text-align:left;">
                            <div class="searchblock" style="width:100%">
                                <el-select v-model="status" style="margin-right:10px;width:80px;" placeholder="请选择"
                                    size="small">
                                    <el-option label="全部" value="1"></el-option>
                                    <el-option label="已上架" value="2"></el-option>
                                    <el-option label="已下架" value="3"></el-option>
                                    <el-option label="库存不足" value="4"></el-option>
                                </el-select>
                                <el-cascader :options="option" v-model="category" placeholder="选择品类" clearable
                                    style="margin-right:10px;width:120px" :show-all-levels="false" :props="props"
                                    @change="SetBrand" size="small"></el-cascader>
                                <el-select v-model="brand" placeholder="选择品牌" filterable
                                    style="margin-right:10px;width:120px" clearable :disabled='category ? false : true'
                                    @change="SetModel" size="small">
                                    <el-option v-for="brand in brands" :key="brand.ID" :label="brand.Brand"
                                        :value="brand.ID"></el-option>
                                </el-select>
                                <el-select v-model="model" placeholder="选择型号" filterable
                                    style="margin-right:10px;width:120px" clearable :disabled='brand ? false : true'
                                    @visible-change="GetModel" size="small">
                                    <el-option v-for="model in models" :key="model.ID" :label="model.Model"
                                        :value="model.ID"></el-option>
                                </el-select>
                                <el-select v-model="pdt" placeholder="选择商品" filterable
                                    style="margin-right:10px;width:120px" clearable :disabled='model ? false : true'
                                    @visible-change="GetPdt" size="small">
                                    <el-option v-for="pdt in pdts" :key="pdt.ID" :label="pdt.PdtName"
                                        :value="pdt.ID"></el-option>
                                </el-select>
                                <el-input v-model="keyword" placeholder="请输入商品编码/套餐编码/套餐标题" prefix-icon="el-icon-search"
                                    class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                                <el-button type="primary" @click="init" style="margin-left:10px;"
                                    class="hidden-sm-and-down" size="small">搜索</el-button>
                                <el-button type="success" @click="add" size="small">添加</el-button>
                                <!-- <el-button type="info" disabled size="small">导出</el-button> -->
                                <el-button type="warning" @click="BatchEdit" size="small">批量编辑</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="content-main">
                    <el-table :data="tabledata" border style="width: 100%; margin: 1em 0" v-loading="load"
                        element-loading-spinner="el-icon-loading" element-loading-text="加载中..."
                        :default-sort="{ prop: 'AddDTime', order: 'descending' }" v-if="activeName != 3"
                        @selection-change="CheckSelection">
                        <el-table-column type="selection"></el-table-column>
                        <el-table-column label="套餐名称" prop="PlanTitle" width="200">
                            <template #default='scope'>
                                <strong>{{ scope.row.PlanTitle }}</strong><br>
                                <span style="font-size:12px;color:#909399">({{ scope.row.paramsname }})</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商户名称" prop="Merchant">
                            <template #default="scope">
                                <span v-if="scope.row.Merchant">{{ scope.row.Merchant }}</span>
                                <span v-else>深圳市闲牛科技有限公司</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="所属商品" prop="PdtName" width="100"></el-table-column>
                        <el-table-column label="套餐编码" prop="PlanCode"></el-table-column>
                        <el-table-column label="商品编码" prop="PdtCode"></el-table-column>
                        <el-table-column label="保障服务" prop="GuarantTitle" width="100">
                            <template #default='scope'>
                                <span v-if="scope.row.GuarantTitle">{{ scope.row.GuarantTitle }}</span>
                                <span v-else> 暂无服务 </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="租赁方式">
                            <template #default='scope'>
                                <span v-if="scope.row.RentType == 1">按天平均</span>
                                <span v-if="scope.row.RentType == 2">按天阶梯</span>
                                <span v-if="scope.row.RentType == 3">按月平均</span>
                                <span v-if="scope.row.RentType == 4">按月阶梯</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="押金" prop="Deposit" width="80"></el-table-column>
                        <el-table-column label="租金范围" width="100">
                            <template #default='scope'>
                                <span v-for="item, key in scope.row.prop" :key="item" v-show="item.val">
                                    <div v-if="key == 'd1'" style="display:inline-block">1天：{{ item.val }}
                                        <div v-if="scope.row.RentType == 1 || scope.row.RentType == 2">元/天</div>
                                        <div v-if="scope.row.RentType == 3 || scope.row.RentType == 4">元/月</div>
                                    </div>
                                    <div v-if="key == 'd3'">3天：{{ item.val }}
                                        <div v-if="scope.row.RentType == 1 || scope.row.RentType == 2">元/天</div>
                                        <div v-if="scope.row.RentType == 3 || scope.row.RentType == 4">元/月</div>
                                    </div>
                                    <div v-if="key == 'd7'">7天：{{ item.val }}
                                        <div v-if="scope.row.RentType == 1 || scope.row.RentType == 2">元/天</div>
                                        <div v-if="scope.row.RentType == 3 || scope.row.RentType == 4">元/月</div>
                                    </div>
                                    <div v-if="key == 'd15'">15天：{{ item.val }}
                                        <div v-if="scope.row.RentType == 1 || scope.row.RentType == 2">元/天</div>
                                        <div v-if="scope.row.RentType == 3 || scope.row.RentType == 4">元/月</div>
                                    </div>
                                    <div v-if="key == 'm1'">1个月：{{ item.val }}
                                        <div v-if="scope.row.RentType == 1 || scope.row.RentType == 2">元/天</div>
                                        <div v-if="scope.row.RentType == 3 || scope.row.RentType == 4">元/月</div>
                                    </div>
                                    <div v-if="key == 'm3'">3个月：{{ item.val }}
                                        <div v-if="scope.row.RentType == 1 || scope.row.RentType == 2">元/天</div>
                                        <div v-if="scope.row.RentType == 3 || scope.row.RentType == 4">元/月</div>
                                    </div>
                                    <div v-if="key == 'm6'">6个月：{{ item.val }}
                                        <div v-if="scope.row.RentType == 1 || scope.row.RentType == 2">元/天</div>
                                        <div v-if="scope.row.RentType == 3 || scope.row.RentType == 4">元/月</div>
                                    </div>
                                    <div v-if="key == 'y1'">1年：{{ item.val }}
                                        <div v-if="scope.row.RentType == 1 || scope.row.RentType == 2">元/天</div>
                                        <div v-if="scope.row.RentType == 3 || scope.row.RentType == 4">元/月</div>
                                    </div>
                                    <div v-if="key == 'y2'">2年：{{ item.val }}
                                        <div v-if="scope.row.RentType == 1 || scope.row.RentType == 2">元/天</div>
                                        <div v-if="scope.row.RentType == 3 || scope.row.RentType == 4">元/月</div>
                                    </div>
                                    <div v-if="key == 'y3'">3年：{{ item.val }}
                                        <div v-if="scope.row.RentType == 1 || scope.row.RentType == 2">元/天</div>
                                        <div v-if="scope.row.RentType == 3 || scope.row.RentType == 4">元/月</div>
                                    </div>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="租期范围" width="80">
                            <template #default='scope'>
                                <span v-if="scope.row.RankStart == 1">1天</span>
                                <span v-if="scope.row.RankStart == 2">3天</span>
                                <span v-if="scope.row.RankStart == 3">7天</span>
                                <span v-if="scope.row.RankStart == 4">15天</span>
                                <span v-if="scope.row.RankStart == 5">1个月</span>
                                <span v-if="scope.row.RankStart == 6">3个月</span>
                                <span v-if="scope.row.RankStart == 7">6个月</span>
                                <span v-if="scope.row.RankStart == 8">1年</span>
                                <span v-if="scope.row.RankStart == 9">2年</span>
                                <span v-if="scope.row.RankStart == 10">3年</span>
                                <br>
                                ~
                                <br>
                                <span v-if="scope.row.RankEnd == 1">1天</span>
                                <span v-if="scope.row.RankEnd == 2">3天</span>
                                <span v-if="scope.row.RankEnd == 3">7天</span>
                                <span v-if="scope.row.RankEnd == 4">15天</span>
                                <span v-if="scope.row.RankEnd == 5">1个月</span>
                                <span v-if="scope.row.RankEnd == 6">3个月</span>
                                <span v-if="scope.row.RankEnd == 7">6个月</span>
                                <span v-if="scope.row.RankEnd == 8">1年</span>
                                <span v-if="scope.row.RankEnd == 9">2年</span>
                                <span v-if="scope.row.RankEnd == 10">3年</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="签约价值" prop="BuyoutPrice" width="80"></el-table-column>
                        <el-table-column label="支持买断" width="80">
                            <template #default='scope'>
                                <span v-if="scope.row.Buyout == 1">是</span>
                                <span v-if="scope.row.Buyout == 2">否</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="库存数量" width="80" prop="Stock">
                            <template #default="scope">
                                <span :style="[scope.row.Stock < 10 ? 'color:red;font-weight:bold' : '']">{{
                                    scope.row.Stock }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" width="80">
                            <template #default='scope'>
                                <span v-if="scope.row.Status == 0">已下架</span>
                                <span v-if="scope.row.Status == 1">已上架</span>
                                <span v-if="scope.row.Status == 2">库存不足</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="100" fixed="right">
                            <template #default='scope'>
                                <el-button type="primary" v-if="scope.row.Status == 0"
                                    @click="Enable(scope.row.ID, scope.$index)">上架</el-button>
                                <el-button type="primary" v-if="scope.row.Status == 1"
                                    @click="Disable(scope.row.ID, scope.$index)">下架</el-button>
                                <el-button :disabled="scope.row.Status == 1 ? true : false" type="primary"
                                    @click="Del(scope.row.ID)">删除</el-button>
                                <br>
                                <el-button type="primary" disabled>预览</el-button>
                                <el-button type="primary" @click="Edit(scope.row.ID)">编辑</el-button>
                                <br>
                                <!--<el-button type="primary" disabled>操作日志</el-button>-->
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-table :data="tabledata" border style="width: 100%; margin: 1em 0" v-loading="load"
                        element-loading-spinner="el-icon-loading" element-loading-text="加载中..."
                        :default-sort="{ prop: 'AddDTime', order: 'descending' }" v-else
                        @selection-change="CheckSelection">
                        <el-table-column type="selection"></el-table-column>
                        <el-table-column label="套餐名称" prop="PlanTitle" width="250"></el-table-column>
                        <el-table-column label="商户名称" prop="Merchant">
                            <template #default="scope">
                                <span v-if="scope.row.Merchant">{{ scope.row.Merchant }}</span>
                                <span v-else>深圳市闲牛科技有限公司</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="所属商品" prop="PdtName" width="100"></el-table-column>
                        <el-table-column label="套餐编码" prop="PlanCode"></el-table-column>
                        <el-table-column label="商品编码" prop="PdtCode"></el-table-column>
                        <el-table-column label="保障服务" prop="GuarantTitle" width="100">
                            <template #default='scope'>
                                <span v-if="scope.row.GuarantTitle">{{ scope.row.GuarantTitle }}</span>
                                <span v-else> 暂无服务 </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="押金" prop="Deposit" width="80"></el-table-column>
                        <el-table-column label="售卖价" prop="RPrice" width="80"></el-table-column>
                        <el-table-column label="原价" prop="Price" width="80"></el-table-column>
                        <el-table-column label="库存数量" width="80" prop="Stock">
                            <template #default="scope">
                                <span :style="[scope.row.Stock < 10 ? 'color:red;font-weight:bold' : '']">{{
                                    scope.row.Stock }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" width="80">
                            <template #default='scope'>
                                <span v-if="scope.row.Status == 0">已下架</span>
                                <span v-if="scope.row.Status == 1">已上架</span>
                                <span v-if="scope.row.Status == 2">库存不足</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="200">
                            <template #default='scope'>
                                <el-button type="primary" v-if="scope.row.Status == 0"
                                    @click="Enable(scope.row.ID, scope.$index)">上架</el-button>
                                <el-button type="primary" v-if="scope.row.Status == 1"
                                    @click="Disable(scope.row.ID, scope.$index)">下架</el-button>
                                <el-button :disabled="scope.row.Status == 1 ? true : false" type="primary"
                                    @click="Del(scope.row.ID)">删除</el-button>
                                <el-button type="primary" disabled>预览</el-button>
                                <el-button type="primary" :disabled="scope.row.Status == 1 ? true : false"
                                    @click="Edit(scope.row.ID)">编辑</el-button>
                                <br>
                                <!--<el-button type="primary" disabled>操作日志</el-button>-->
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
                        :current-page="curpage" :page-size="pagesize" :page-sizes='[10, 20, 30, 50, 100]'
                        layout="sizes,total, prev, pager, next, jumper" :total="totalcount" :hide-on-single-page='true'
                        style="text-align:center"></el-pagination>
                </div>
            </el-tab-pane>
        </el-tabs>
    </el-card>
    <el-dialog v-model="confirm" title="批量编辑" center width="20%">
        <el-radio-group v-model="type" size="small">
            <el-radio-button :label="1">上架</el-radio-button>
            <el-radio-button :label="2">下架</el-radio-button>
        </el-radio-group>
        <el-divider></el-divider>
        <el-button type="primary" @click="ConfirmBatch">确认</el-button>
        <el-button @click="Cancel">取消</el-button>
    </el-dialog>
    <el-dialog v-model="editplan" title="编辑套餐信息" center width="70%">
        <div style="margin-bottom:25px;border-bottom:1px solid #dcdfe6"></div>
        <el-form :model="planinfo" ref="planinfo" label-width="120px" :inline="true" :rules="rules">
            <el-form-item label="套餐标题" prop="title" required>
                <el-input v-model="planinfo.title" style="width:215px;"></el-input>
            </el-form-item>
            <el-form-item label="库存" prop="stock" required>
                <el-input-number style="width:215px;" v-model="planinfo.stock" controls-position="right"
                    min="0"></el-input-number>
            </el-form-item>
            <el-form-item label="售卖价(元)" prop="rprice" v-if="planinfo.plantype == 3">
                <el-input-number style="width:215px;" v-model="planinfo.rprice" min="0"
                    controls-position="right"></el-input-number>
            </el-form-item>
            <el-form-item label="原价(元)" prop="price" v-if="planinfo.plantype == 3">
                <el-input-number style="width:215px;" v-model="planinfo.price" min="0"
                    controls-position="right"></el-input-number>
            </el-form-item>
            <el-form-item label="押金(元)" prop="deposit" v-if="planinfo.plantype != 3">
                <el-input-number style="width:215px;" v-model="planinfo.deposit" min="0"
                    controls-position="right"></el-input-number>
            </el-form-item>
            <el-form-item label="成本价(元)" prop="margin" v-if="planinfo.plantype != 3">
                <el-input-number style="width:215px;" v-model="planinfo.margin" min="0"
                    controls-position="right"></el-input-number>
            </el-form-item>
            <el-form-item label="签约价值(元)" prop="buyoutprice"
                v-if="planinfo.plantype == 1 || planinfo.plantype == 2 || planinfo.plantype == 4">
                <el-input-number style="width:215px;" v-model="planinfo.buyoutprice" min="0"
                    controls-position="right"></el-input-number>
            </el-form-item>
            <el-form-item style="width:100%" label="是否可以买断" prop="buyout" required
                v-if="planinfo.plantype == 1 || planinfo.plantype == 2 || planinfo.plantype == 4">
                <el-radio-group v-model="planinfo.buyout" style="width: 215px">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="2">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="是否可以续租" prop="rerent" required
                v-if="planinfo.plantype == 1 || planinfo.plantype == 2 || planinfo.plantype == 4">
                <el-radio-group v-model="planinfo.rerent" style="width: 215px">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="2">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="续租方案" prop="rerentprice" v-if="planinfo.rerent == 1"
                :required="planinfo.rerent == 1 ? true : false">
                <el-input-number style="width:215px;" v-model="planinfo.rerentprice" :min="0.8" :max="1.6"
                    controls-position="right" :step="0.1" :precision="1"></el-input-number>
                <el-tag type="danger" size="mini" style="margin-left:10px;">*填写0.8-1.6范围内的数字</el-tag>
            </el-form-item>
            <el-form-item style="width:100%" label="选择租期范围" prop="rentrank"
                v-if="planinfo.plantype == 1 || planinfo.plantype == 2 || planinfo.plantype == 4">
                <el-select v-model="planinfo.rankstart" placeholder="请选择" @change="SetTable">
                    <el-option label="1天" value="1" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                    <el-option label="3天" value="2" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                    <el-option label="7天" value="3" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                    <el-option label="15天" value="4"
                        v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                    <el-option label="1个月" value="5"></el-option>
                    <el-option label="3个月" value="6"></el-option>
                    <el-option label="6个月" value="7"></el-option>
                    <el-option label="12个月" value="8"></el-option>
                    <el-option label="24个月" value="9"></el-option>
                    <el-option label="36个月" value="10"></el-option>
                </el-select>
                <span style="width:15px"> - </span>
                <el-select v-model="planinfo.rankend" placeholder="请选择" @change="SetTable">
                    <el-option label="1天" value="1" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                    <el-option label="3天" value="2" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                    <el-option label="7天" value="3" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                    <el-option label="15天" value="4"
                        v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                    <el-option label="1个月" value="5"></el-option>
                    <el-option label="3个月" value="6"></el-option>
                    <el-option label="6个月" value="7"></el-option>
                    <el-option label="12个月" value="8"></el-option>
                    <el-option label="24个月" value="9"></el-option>
                    <el-option label="36个月" value="10"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="pricelabel" style="width: 100%;" v-if="planinfo.plantype != 3">
                <el-table :data="planinfo.tabledata" border style="width: 100%; margin: 1em 0;" size="mini">
                    <el-table-column v-for="column in planinfo.tablecolumn" :key="column.label" :label="column.label"
                        :prop="column.prop">
                        <template #default='scope'>
                            <el-input v-model="scope.row[column.prop].val" min="0"
                                @change="CheckRentPrice(scope.row[column.prop].val, scope.$index, column.prop)"
                                size="mini" :disabled="scope.row[column.prop].disabled"></el-input>
                        </template>
                    </el-table-column>
                </el-table>
                <el-tag type="danger" size="small">列表从上至下分别为 所选租期/租金/总租金</el-tag>
            </el-form-item>
            <!--
                <el-form-item label="保障服务" style="width:100%">
                    <el-checkbox-group v-model="planinfo.guarante" class="gurant-group" @change="ResetGroup">
                                <div v-if="planinfo.guarantinfo.length > 0">
                                    <div v-for="guarante in guarantes" :key="guarante">
                                        <el-checkbox :label="guarante.ID">{{guarante.GuarantTitle}}</el-checkbox>
                                        <el-card v-if="planinfo.guarante.indexOf(guarante.ID)!=-1" >
                                            <div class="gurantopt">
                                                <div class="guarantlabel">
                                                    <span>价格(元)：</span>
                                                    <el-input v-model="guarante.Price" size="mini" @change="SetGuarantInfo(guarante.Price)" ></el-input>
                                                </div>
                                                <div   class="guarantlabel">
                                                <span>是否必选：</span>
                                                <el-radio-group v-model="guarante.MustBuy" @change="Check">
                                                    <el-radio :label="1">是</el-radio>
                                                    <el-radio :label="2">否</el-radio>
                                                </el-radio-group>
                                                </div>
                                                <div  class="guarantlabel">
                                                <span>是否默认勾选：</span>
                                                <el-radio-group v-model="guarante.DefaultBuy" @change="Check">
                                                    <el-radio :label="1">是</el-radio>
                                                    <el-radio :label="2">否</el-radio>
                                                </el-radio-group>
                                                </div>
                                            </div>
                                        </el-card>
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-for="guarante in guarantes" :key="guarante">
                                        <el-checkbox :label="guarante.ID">{{guarante.GuarantTitle}} </el-checkbox>
                                        <el-card v-if="planinfo.guarante.indexOf(guarante.ID)!=-1">
                                            <div class="gurantopt">
                                                <div class="guarantlabel">
                                                    <span>价格(元)：</span>
                                                    <el-input v-model="guarante.Price" size="mini" @change="SetGuarantInfo(guarante.Price)" ></el-input>
                                                </div>
                                                <div   class="guarantlabel">
                                                <span>是否必选：</span>
                                                <el-radio-group v-model="guarante.MustBuy">
                                                    <el-radio :label="1">是</el-radio>
                                                    <el-radio :label="2">否</el-radio>
                                                </el-radio-group>
                                                </div>
                                                <div  class="guarantlabel">
                                                <span>是否默认勾选：</span>
                                                <el-radio-group v-model="guarante.DefaultBuy">
                                                    <el-radio :label="1">是</el-radio>
                                                    <el-radio :label="2">否</el-radio>
                                                </el-radio-group>
                                                </div>
                                            </div>
                                        </el-card>
                                    </div>
                                </div>
                                </el-checkbox-group>
                </el-form-item>-->
        </el-form>
        <div class="bottom">
            <el-button size="small" type="success" @click="Sumbitform('planinfo')">保存</el-button>
            <el-button size="small" @click="CloseDialog">取消</el-button>
        </div>
    </el-dialog>
    <el-dialog v-model="merchant" title="选择商户" center>
        <div class="merchant-block">
            <el-transfer v-model="selmerchant" filterable filter-placeholder="请输入商户名称" :data='merchantlist'
                :titles='transfertitle'>
            </el-transfer>
            <div class="merchant-bottom">
                <el-button type="primary" size="small" @click="ConfirmMerchant">确定</el-button>
                <el-button size="small" @click="Back">取消</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import Cookies from 'js-cookie'
import { ElMessage } from 'element-plus'
import constant from "@/constant"
export default {
    data() {
        return {
            selmerchant: [],
            transfertitle: ['商户列表', '已选商户'],
            merchantlist: [],
            merchant: false,
            load: false,
            curpage: 1,
            pagesize: 10,
            totalcount: "",
            editplan: false,
            confirm: false,
            type: '',
            channels: [{
                label: '租赁',
                name: '1'
            }, {
                label: '到期赠送',
                name: '2'
            }, {
                label: '售卖',
                name: '3'
            }, {
                label: '定制租赁',
                name: '4'
            }],
            props: {
                label: 'label',
                value: 'value',
                disabled: 'Disabled'
            },
            option: [],
            activeName: '1',
            tabledata: [],
            status: '1',
            plantype: '1',
            category: '',
            brand: '',
            model: '',
            keyword: '',
            pdt: '',
            categorys: [],
            brands: [],
            models: [],
            pdts: [],
            pricelabel: '租金(按天平均)',
            nocatesel: true,
            nobrandsel: true,
            nomodelsel: true,
            selection: [],
            guarantes: [],
            planinfo: {
                id: '',
                plantype: '1',
                renttype: 1,
                rankstart: '1',
                rankend: '8',
                //guarantinfo:[],
                //guarante:[],
                tabledata: [],
                tablecolumn: [],
                buyout: 2,
                rerent: 2,
                buyoutprice: 0,
                rprice: 0,
                price: 0,
                margin: '',
                deposit: '',
            },
            rules: {
                title: [{
                    required: true,
                    message: '请输入套餐标题',
                    trigger: 'blur'
                }],
                stock: [{
                    required: true,
                    message: '请输入库存',
                    trigger: 'blur'
                }],
            }
        }
    },
    methods: {
        ConfirmMerchant() {
            console.log(this.selmerchant);
            this.merchant = false;
        },
        Back() {
            this.merchant = false;
        },
        SelectMerchant() {
            this.merchant = true;
        },
        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        ResetGroup() {
            //console.log('reset');
            //console.log(this.planinfo.guarante);
            //console.log(this.planinfo.guarantinfo);
            var newinfo = [];
            for (let i = 0; i < this.planinfo.guarante.length; i++) {
                for (let j = 0; j < this.guarantes.length; j++) {
                    if (this.guarantes[j].ID == this.planinfo.guarante[i]) {
                        newinfo.push(this.guarantes[j]);
                    }
                }
            }
            console.log(newinfo);
            this.planinfo.guarantinfo = newinfo;
        },
        Sumbitform(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ResetGroup();
                    this.planinfo.key = this.key;
                    console.log(this.planinfo);
                    this.axios.post(constant.save_plan_url, this.planinfo, {
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    }).then((response) => {
                        console.log(response.data);
                        if (response.data == 'OK') {
                            ElMessage.success({
                                type: 'success',
                                message: '保存成功'
                            });
                            this.init();
                            this.editplan = false;
                            return false;
                        } else if (response.data == 'REPEATTITLE') {
                            this.$message.error("该产品的套餐标题不允许重复");
                            return false;
                        } else if (response.data == 'LOCK') {
                            this.$message.error("该产品的套餐正参与活动被锁定中，请联系运维管理员解锁");
                            return false;
                        } else {
                            ElMessage.error({
                                type: 'error',
                                message: '保存失败'
                            });
                            return false;
                        }
                    });
                } else {
                    return false;
                }
            })
        },
        CloseDialog() {
            this.editplan = false;
            this.guarantes = [];
            this.planinfo.guarantinfo = [];
            this.planinfo.guarante = [];
        },
        SetGurantGroup() {
            this.planinfo.guarantinfo = []
            for (let i = 0; i < this.planinfo.guarante.length; i++) {
                for (let j = 0; j < this.guarantes.length; j++) {
                    if (this.guarantes[j].ID == this.planinfo.guarante[i]) {
                        this.planinfo.guarantinfo.push(this.guarantes[j]);
                    }
                }
            }
            console.log(this.planinfo.guarantinfo);
        },
        SetGuarantInfo(val) {
            if (!typeof val === 'number' || isNaN(val)) {
                ElMessage.error({
                    type: 'error',
                    message: '保障价格必须是数字'
                });
                return false;
            }
        },
        SetTable() {
            console.log("重置列表");
            var tablecolumn = Array();
            for (var key in this.rank) {
                var cur_rank_obj = this.rank[key];
                var rank_obj = Object();
                if (this.planinfo.renttype == 3 || this.planinfo.renttype == 4)
                    rank_obj.label = cur_rank_obj.label + "(元/月)";
                else
                    rank_obj.label = cur_rank_obj.label + "(元/天)";
                rank_obj.prop = cur_rank_obj.prop
                rank_obj.val = cur_rank_obj.val;
                tablecolumn.push(rank_obj);
            }
            console.log(tablecolumn);
            this.planinfo.tablecolumn = tablecolumn;
            var tabledata = Array();
            var row_obj = Object();//填写价格的行
            var total_row_obj = Object();//计算总租金的行
            if (this.planinfo.plantype != 3) {
                for (let key in this.rank) {
                    let cur_rank_obj = this.rank[key];
                    let rank_prop = cur_rank_obj.prop;
                    let cur_obj = Object();
                    let total_cur_obj = Object();
                    if (key * 1 >= (this.planinfo.rankstart) * 1 && (this.planinfo.rankend) * 1 >= key * 1) {
                        if (this.planinfo.plantype != 2) {
                            cur_obj.val = '';
                            total_cur_obj.val = '';
                        }
                        else {
                            if (key * 1 == 5) {
                                cur_obj.val = (this.planinfo.buyoutprice / 1).toFixed(2);
                                total_cur_obj.val = (cur_obj.val * 1).toFixed(2);
                            }
                            if (key * 1 == 6) {
                                cur_obj.val = (this.planinfo.buyoutprice / 3).toFixed(2);
                                total_cur_obj.val = (cur_obj.val * 3).toFixed(2);
                            }
                            if (key * 1 == 7) {
                                cur_obj.val = (this.planinfo.buyoutprice / 6).toFixed(2);
                                total_cur_obj.val = (cur_obj.val * 6).toFixed(2);
                            }
                            if (key * 1 == 8) {
                                cur_obj.val = (this.planinfo.buyoutprice / 12).toFixed(2);
                                total_cur_obj.val = (cur_obj.val * 12).toFixed(2);
                            }
                            if (key * 1 == 9) {
                                cur_obj.val = (this.planinfo.buyoutprice / 24).toFixed(2);
                                total_cur_obj.val = (cur_obj.val * 24).toFixed(2);
                            }
                            if (key * 1 == 10) {
                                cur_obj.val = (this.planinfo.buyoutprice / 36).toFixed(2);
                                total_cur_obj.val = (cur_obj.val * 36).toFixed(2);
                            }
                        }
                        cur_obj.disabled = false;
                        total_cur_obj.disabled = true;
                    } else {
                        cur_obj.val = '';
                        cur_obj.disabled = true;
                        total_cur_obj.val = '';
                        total_cur_obj.disabled = true;
                    }
                    row_obj[rank_prop] = cur_obj;
                    total_row_obj[rank_prop] = total_cur_obj;
                }
            }
            tabledata.push(row_obj);
            tabledata.push(total_row_obj);
            this.planinfo.tabledata = [];
            this.planinfo.tabledata = tabledata;
            console.log(this.planinfo.tabledata);
        },
        CheckRentPrice(val, index, prop) {
            console.log(val);
            console.log(index);
            console.log(prop);
            if (val) {
                if (isNaN(val)) {
                    this.$message.error("金额必须是数字");
                    return false;
                }
            }
            var total_row = this.planinfo.tabledata[1];
            var cur_row = this.planinfo.tabledata[0];
            if (prop == 'd1') {
                total_row[prop].val = val * 1;
            }
            if (prop == 'd3') {
                if (this.planinfo.rankstart != 2 && val * 1 > cur_row.d1.val * 1)
                    this.$message.error("后一阶的租金不能高于前一阶")
                total_row[prop].val = (val * 3).toFixed(2);
            }
            if (prop == 'd7') {
                if (this.planinfo.rankstart != 3 && (val * 1 > cur_row.d3.val * 1))
                    this.$message.error("后一阶的租金不能高于前一阶")
                total_row[prop].val = (val * 7).toFixed(2);
            }
            if (prop == 'd15') {
                if (this.planinfo.rankstart != 4 && (val * 1 > cur_row.d7.val * 1))
                    this.$message.error("后一阶的租金不能高于前一阶")
                total_row[prop].val = (val * 15).toFixed(2);
            }
            if (prop == 'm1') {
                if (this.planinfo.rankstart != 5 && (val * 1 > cur_row.d15.val * 1))
                    this.$message.error("后一阶的租金不能高于前一阶")
                if (this.planinfo.renttype == 1 || this.planinfo.renttype == 2)
                    total_row[prop].val = (val * 30).toFixed(2);
                else
                    total_row[prop].val = (val * 1).toFixed(2);
            }
            if (prop == 'm3') {
                if (this.planinfo.rankstart != 6 && (val * 1 > cur_row.m1.val * 1))
                    this.$message.error("后一阶的租金不能高于前一阶")
                if (this.planinfo.renttype == 1 || this.planinfo.renttype == 2)
                    total_row[prop].val = (val * 90).toFixed(2);
                else
                    total_row[prop].val = (val * 3).toFixed(2);
            }
            if (prop == 'm6') {
                if (this.planinfo.rankstart != 7 && (val * 1 > cur_row.m3.val * 1))
                    this.$message.error("后一阶的租金不能高于前一阶")
                if (this.planinfo.renttype == 1 || this.planinfo.renttype == 2)
                    total_row[prop].val = (val * 180).toFixed(2);
                else
                    total_row[prop].val = (val * 6).toFixed(2);
            }
            if (prop == 'y1') {
                if (this.planinfo.rankstart != 8 && (val * 1 > cur_row.m6.val * 1))
                    this.$message.error("后一阶的租金不能高于前一阶")
                if (this.planinfo.renttype == 1 || this.planinfo.renttype == 2)
                    total_row[prop].val = (val * 365).toFixed(2);
                else
                    total_row[prop].val = (val * 12).toFixed(2);
            }
            if (prop == 'y2') {
                if (this.planinfo.rankstart != 9 && (val * 1 > cur_row.y1.val * 1))
                    this.$message.error("后一阶的租金不能高于前一阶")
                if (this.planinfo.renttype == 1 || this.planinfo.renttype == 2)
                    total_row[prop].val = (val * 730).toFixed(2);
                else
                    total_row[prop].val = (val * 24).toFixed(2);
            }
            if (prop == 'y3') {
                if (this.planinfo.rankstart != 10 && (val * 1 > cur_row.y2.val * 1))
                    this.$message.error("后一阶的租金不能高于前一阶")
                if (this.planinfo.renttype == 1 || this.planinfo.renttype == 2)
                    total_row[prop].val = (val * 1095).toFixed(2);
                else
                    total_row[prop].val = (val * 36).toFixed(2);
            }
        },
        SetBrand() {
            this.SetBrand = "";
        },
        SetModel() {
            this.model = "";
        },
        Cancel() {
            this.type = "";
            this.confirm = false;
        },
        GetModel() {
            this.loading = true;
            this.axios.get(constant.get_plan_model_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    category: JSON.stringify(this.category),
                    brand: this.brand,
                    status: 1,
                }
            }).then((response) => {
                console.log(response.data);
                this.models = response.data;
                this.loading = false;
            });
        },
        ConfirmBatch() {
            if (!this.type) {
                this.$message.error("请选择要执行的操作");
                return false;
            } else {
                this.axios.get(constant.batch_edit_plan, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {
                        type: this.type,//上下架
                        confirmtype: 1,//是产品还是套餐
                        ids: JSON.stringify(this.selection),
                    }
                }).then((response) => {
                    console.log(response.data);
                    this.$message.success("操作成功");
                    this.confirm = false;
                    this.$router.go(0);
                });
            }
        },
        BatchEdit() {
            if (this.selection.length <= 0) {
                this.$message.error("请选择要操作的对象");
                return false;
            } else {
                this.confirm = true;
            }
        },
        CheckSelection(rows) {
            //this.selectrows = rows;
            this.selection = [];
            if (rows) {
                rows.forEach(row => {
                    this.selection.push(row.ID);
                })
            }
            console.log(this.selection);
        },
        add() {
            this.$router.push({ path: '/addplan' });
        },
        handleSizeChange(val) {
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        init() {
            this.load = true;
            this.axios.get(constant.get_plan_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    category: JSON.stringify(this.category),
                    brand: this.brand,
                    model: this.model,
                    keyword: this.keyword,
                    status: this.status,
                    pdt: this.pdt,
                    plantype: this.activeName,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    ismerchant: this.ismerchant,
                    mid: this.mid,
                    selmerchant: JSON.stringify(this.selmerchant)
                }
            }).then((response) => {
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage * 1;
                this.totalcount = response.data.totalcount * 1;
                this.load = false;
            });
        },
        SwitchTab() {
            this.nocatesel = true;
            this.nobrandsel = true;
            this.category = '';
            this.brand = '';
            this.model = '';
            this.keyword = '';
            this.tabledata = '';
            this.axios.get(constant.get_plan_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    category: JSON.stringify(this.category),
                    brand: this.brand,
                    model: this.model,
                    keyword: this.keyword,
                    status: this.status,
                    pdt: this.pdt,
                    plantype: this.activeName,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    ismerchant: this.ismerchant,
                    mid: this.mid,
                    selmerchant: JSON.stringify(this.selmerchant)
                }
            }).then((response) => {
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage * 1;
                this.totalcount = response.data.totalcount * 1;
            });
        },
        Del(id) {
            let cur = this;
            if (!confirm("您确定要删除该套餐么？"))
                return false;
            this.axios.get(constant.del_plan_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: id,
                    plantype: this.activeName
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data.msg == "OK") {
                    //let active = response.data.activeName;
                    ElMessage.success({
                        type: 'success',
                        message: '删除成功'
                    });
                    cur.$router.go(0);
                } else {
                    return false;
                }
            })
        },
        Enable(id, index) {
            console.log(id);
            this.axios.get(constant.enable_plan_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data == "OK") {
                    ElMessage.success({
                        type: 'success',
                        message: '上架成功'
                    });
                    //this.$router.go(0);
                    this.tabledata[index].Status = 1;
                } else {
                    this.$message.error("信息不全，无法上架");
                    return false;
                }
            })
        },
        Disable(id, index) {
            console.log(id);
            this.axios.get(constant.disable_plan_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data == "OK") {
                    ElMessage.success({
                        type: 'success',
                        message: '下架成功'
                    });
                    //this.$router.go(0);
                    this.tabledata[index].Status = 0;
                } else {
                    return false;
                }
            })
        },
        SetCategory() {
            this.nocatesel = false;
        },
        ClearCategory() {
            this.nocatesel = true;
            this.nobrandsel = true;
            this.brand = '';
            this.model = '';
        },
        ClearBrand() {
            this.nobrandsel = true;
            this.nomodelsel = true;
            this.model = '';
        },
        AlterModelList() {
            this.nobrandsel = true;
            this.axios.get(constant.get_plan_model_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    category: this.category,
                    brand: this.brand,
                }
            }).then((response) => {
                console.log(response.data);
                this.model = '';
                this.models = response.data;
                this.nobrandsel = false;
                //this.GetPdt();
            });
        },
        GetPdt() {
            this.axios.get(constant.get_plan_pdt_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    category: JSON.stringify(this.category),
                    brand: this.brand,
                    model: this.model,
                    status: 1,
                    merchantid: this.mid,
                }
            }).then((response) => {
                this.pdts = response.data;
            });
        },
        Edit(id) {
            console.log(id);
            this.axios.get(constant.edit_plan_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                this.editplan = true;
                this.planinfo.stock = response.data.planinfo.Stock * 1;
                this.planinfo.title = response.data.planinfo.PlanTitle;
                this.planinfo.id = response.data.planinfo.ID;
                this.planinfo.margin = response.data.planinfo.Margin * 1;
                this.planinfo.deposit = response.data.planinfo.Deposit * 1;
                this.planinfo.rprice = response.data.planinfo.RPrice * 1;
                this.planinfo.price = response.data.planinfo.Price * 1;
                this.planinfo.buyout = response.data.planinfo.Buyout * 1;
                this.planinfo.buyoutprice = response.data.planinfo.BuyoutPrice * 1;
                this.planinfo.rerent = response.data.planinfo.Rerent * 1;
                this.planinfo.rerentprice = response.data.planinfo.RerentPrice * 1;
                this.planinfo.rankstart = response.data.planinfo.RankStart;
                this.planinfo.rankend = response.data.planinfo.RankEnd;
                this.planinfo.tablecolumn = response.data.planinfo.TableColumn;
                this.planinfo.tabledata = response.data.planinfo.Tabledata;
                this.planinfo.plantype = response.data.planinfo.Plantype;
                this.planinfo.renttype = response.data.planinfo.RentType;
                if (this.planinfo.renttype == 3)
                    this.pricelabel = "租金(按月平均)";
                if (this.planinfo.renttype == 4)
                    this.pricelabel = "租金(按月阶梯)";
                if (this.planinfo.renttype == 1)
                    this.pricelabel = "租金(按天平均)";
                if (this.planinfo.renttype == 2)
                    this.pricelabel = "租金(按天阶梯)";
                this.planinfo.guarante = [];
                if (response.data.planinfo.guarante)
                    this.planinfo.guarante = response.data.planinfo.guarante;
                this.planinfo.guarantinfo = [];
                if (response.data.planinfo.guarantinfo)
                    this.planinfo.guarantinfo = response.data.planinfo.guarantinfo;
                console.log("info");
                console.log(this.planinfo.guarante);
                console.log(this.planinfo.guarantinfo);
                this.guarantes = response.data.guarantes;
                this.axios.get(constant.get_guarant_url, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {
                        status: 1,
                        ismerchant: this.ismerchant,
                        mid: this.mid,
                        planid: id,
                    }
                }).then((response) => {
                    console.log("this.guarantes");
                    console.log(response.data);
                    this.guarantes = response.data;
                    if (this.planinfo.guarantinfo) {
                        for (let i = 0; i < this.guarantes.length; i++) {
                            let cur_id = this.guarantes[i]['ID'];
                            for (let j = 0; j < this.planinfo.guarantinfo.length; j++) {
                                if (cur_id == this.planinfo.guarantinfo[j]['GuarantID']) {
                                    this.guarantes[i].Price = this.planinfo.guarantinfo[j]['Price'];
                                    this.guarantes[i].MustBuy = this.planinfo.guarantinfo[j]['MustBuy'];
                                    this.guarantes[i].DefaultBuy = this.planinfo.guarantinfo[j]['DefaultBuy'];
                                }
                            }
                        }
                    }
                })
            })
        }
    },
    created: function () {
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        if (Cookies.get("mid"))
            this.planinfo.merchantid = Cookies.get("mid");
        let id = this.$route.query.id;
        let planid = this.$route.query.planid;
        if (!id)
            this.activeName = '1';
        else
            this.activeName = id;
        this.axios.get(constant.get_plan_url, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                plantype: this.activeName,
                curpage: this.curpage,
                pagesize: this.pagesize,
                ismerchant: this.ismerchant,
                mid: this.mid,
            }
        }).then((response) => {
            this.tabledata = response.data.list;
            console.log(this.tabledata);
            this.curpage = response.data.curpage * 1;
            this.totalcount = response.data.totalcount * 1;
        });
        this.axios.get(constant.get_cascader_url, {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response.data);
            this.option = response.data;
        });
        this.axios.get(constant.get_brand_url, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                status: 1
            }
        }).then((response) => {
            console.log(response.data);
            this.brands = response.data;
        });
        this.axios.get(constant.get_rank_url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response.data);
            this.rank = response.data;
        });
        if (planid) {
            this.Edit(planid);
        }
        this.axios.get(constant.allmerchant, {
            headers: {
                "Content-Type": 'application/json'
            }
        }).then((response) => {
            console.log("商户列表");
            console.log(response.data);
            let list = response.data;
            this.merchantlist = [];
            for (let i = 0; i < list.length; i++) {
                let obj = new Object();
                obj.label = list[i]['Merchant'];
                obj.key = list[i]['ID'];
                this.merchantlist.push(obj);
            }
        });
    }
}
</script>
<style>
.el-card {
    padding: 0 !important;
}

.el-card__body {
    padding: 10px;
}

.el-card__header {
    padding: 10px !important;
}

.gurant-group {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.guarantlabel {
    width: 150px;
    margin-right: 10px;
}

.gurantopt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bottom {
    margin-top: 50px;
    padding-top: 13px;
    line-height: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /*border-top: #dcdfe6 1px solid;*/
}

.merchant-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>